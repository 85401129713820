import { Theme } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import style from './style';

const Styled = styled.div`
  ${style}
`;
export const ThemeToggle = ({ isOpen }) => {
  const { setThemeName, themeName } = Theme.useThemeContext();

  const handleClick = React.useCallback(() => {
    setThemeName(themeName === 'dark' ? 'light' : 'dark');
  }, [setThemeName, themeName]);

  return (
    <Styled
      className={cn('theme-toggle', { open: isOpen, light: themeName === 'light', dark: themeName === 'dark' })}
      onClick={handleClick}
      data-testid="theme-toggle"
    >
      <div className="theme-outer">
        <div className={cn('theme-inner')}>
          <div className="icon-section">
            <div className="icon light">
              <i className="fa-thin fa-moon" />
            </div>
            <div className="icon dark">
              <i className="fa-thin fa-sun-bright" />
            </div>
          </div>
          <div className="label">{`Switch To ${themeName === 'dark' ? 'Light' : 'Dark'} Theme`}</div>
        </div>
      </div>
    </Styled>
  );
};
