import cn from 'classnames';
import { User, useAppContext } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import { usePostMessageListener } from '../../../util/usePostMessageListener';

const Styled = styled.div`
  height: 25px;
  background-color: #e81a20;
  color: yellow;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

/*
  Show warning when either condition is met:
    1. Running on localhost and pointed to production api regardless of role.
    2. Pointed to a production api while in a platform-admin role.
*/

const reducer = (state, action) => {
  const {
    message: { service, isProductionApp, isLocalhostApp, productionOverrides },
    isPlatformAdmin,
  } = action;
  //sendMessage({ type: 'api-info', service: apiConfig.id, isProductionApp, isLocalhostApp, productionOverrides })

  return {
    ...state,
    [service]: (isLocalhostApp && productionOverrides) || (isPlatformAdmin && isProductionApp),
  };
};

export const ProductionWarning = () => {
  const { appRef } = useAppContext();
  const [isProductionApi, dispatch] = React.useReducer(reducer, {}, undefined);
  const { user } = User.useUserContext();

  const isPlatformAdmin = user?.isPlatformAdmin;

  const listener = React.useCallback(
    event => {
      const message = event?.data;
      if (message?.type === 'api-info') {
        dispatch({ message, isPlatformAdmin });
      }
    },
    [isPlatformAdmin]
  );

  usePostMessageListener(listener);

  const showWarning = React.useMemo(() => {
    return isPlatformAdmin && isProductionApi[appRef];
  }, [appRef, isPlatformAdmin, isProductionApi]);

  return showWarning ? <Styled className={cn('production-warning')}>P R O D U C T I ON</Styled> : null;
};
