import cn from 'classnames';
import { Localization, useEntityContext, useAppContext } from 'connex-cds';
import { find } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { cacheItem } from '../../../../util/cache';
import { useClickOutside } from '../../../../util/useClickOutside';
import { useEscape } from '../../../../util/useEscape';
import style from './style';

const Styled = styled.div`
  ${style}
`;

export const EntitySwitcher = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  const { entityRef, setEntityRef } = useEntityContext();
  const { appEntities } = useAppContext();

  const handleChange = React.useCallback(
    val => {
      setIsOpen(false);
      setEntityRef(val);
      cacheItem('entityRef', val);
    },
    [setEntityRef]
  );

  const options = React.useMemo(() => {
    const entities = appEntities?.map?.(option => ({ id: option.entityRef, label: option.name })) || [];

    // Don't show "Select an entity..." if and entity is already selected.
    return entityRef
      ? entities
      : [
          { id: '', label: <Localization.Translate stringId="selectCompany" data-testid="select-company" /> },
          ...entities,
        ];
  }, [entityRef, appEntities]);

  const selectedEntity = React.useMemo(() => {
    return find(options, { id: entityRef || '' }) || '';
  }, [entityRef, options]);

  useEscape(isOpen, () => setIsOpen(false));
  const ref = useClickOutside(() => setIsOpen(false));

  return (
    <>
      <Styled
        className={cn('entity-switcher', { open: isOpen })}
        ref={ref}
        $lineCount={options.length}
        onClick={
          isOpen
            ? e => {
                setIsOpen(false);
                e.stopPropagation();
              }
            : undefined
        }
      >
        <div
          className="current-item"
          onClick={() => {
            setIsOpen(s => !s);
          }}
        >
          <i className="icon fa-solid fa-buildings" />
          {selectedEntity?.label}
          <div className="arrow">
            <i className="fa-sharp fa-solid fa-caret-down" />
          </div>
        </div>
        <div className="items">
          <div className="options">
            {options
              .filter(option => option.id !== selectedEntity.id)
              .map(option => {
                return (
                  <div key={option.id} onClick={() => handleChange(option.id)}>
                    {option.label}
                  </div>
                );
              })}
          </div>
        </div>
      </Styled>
    </>
  );
};
