import { Form } from 'connex-cds';
import * as yup from 'yup';

export default {
  validationSchema: yup.object().shape({
    password: yup.string().required('required'),
  }),
  fields: [
    {
      path: 'password',
      name: 'password',
      labelStringId: 'password',
      dataType: Form.FIELD_TYPES.string,
      password: true,
      initialFocus: true,
      testId: 'password',
    },
  ],
};
