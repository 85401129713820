import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';

const Styled = styled.div`
  border: 1px solid var(--color-icon-alert-error);
  background-color: var(--color-background-alert-error);
  border-radius: 10px;
  padding: 10px 20px;
  margin-bottom: 20px;
`;
export const DisplayError = ({ error }) => {
  if (!error) return null;

  return (
    <Styled className={cn('display-error')}>
      {error?.issues?.map?.(issue => issue.type)?.join(', ') || error?.message || 'An error has occurred.'}
    </Styled>
  );
};
