import cn from 'classnames';
import { Core, Form, Drawer } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import { ChangePassword } from './change-password/ChangePassword';
import { ForgetMe } from './forget-me/ForgetMe';

const Styled = styled.div`
  .actions {
    display: flex;
    justify-content: flex-end;
    .left {
      display: flex;
      flex: 1;
      justify-content: flex-start;
      gap: 20px;
    }
    .right {
      display: flex;
      flex: 1;
      justify-content: flex-end;
    }
  }
  .ant-alert {
    margin-bottom: 15px;
  }
`;

export const ProfileForm = () => {
  const { openDrawer, closeDrawer } = Drawer.useDrawerContext();
  const [success, setSuccess] = React.useState(undefined);

  const { Components } = Form.useFormContext();

  const handleSuccess = React.useCallback(
    stringId => {
      closeDrawer();
      setSuccess(stringId);
      setTimeout(() => {
        setSuccess(undefined);
      }, 3000);
    },
    [closeDrawer]
  );

  const handleChangePasswordClick = React.useCallback(() => {
    openDrawer({
      titleStringId: 'changePassword',
      component: <ChangePassword onSuccess={handleSuccess} />,
    });
  }, [handleSuccess, openDrawer]);

  const handleForgetMeClick = React.useCallback(() => {
    openDrawer({
      titleStringId: 'forgetMe',
      component: <ForgetMe />,
    });
  }, [openDrawer]);

  return (
    <Styled className={cn('profile-form')}>
      {success && <Core.Alert severity={Core.SEVERITY.SUCCESS} message={success} closable showIcon />}
      <div className="name">
        <Components.FirstName />
        <Components.LastName />
      </div>
      <div className="contact">
        <Components.Email disabled />
        <Components.PhoneNumber />
      </div>
      <div className="actions">
        <div className="left">
          <Core.Button
            stringId="changePassword"
            data-testid="change-password-button"
            onClick={handleChangePasswordClick}
          />
          <Core.Button
            stringId="forgetMe"
            data-testid="forget-me-button"
            onClick={handleForgetMeClick}
            type="primary"
            danger
          />
        </div>
        <div className="right">
          <Components.SubmitButton stringId="save" data-testid="save-button" disabledWhenNotDirty={false} />
        </div>
      </div>
    </Styled>
  );
};
