import { css } from 'styled-components';

export default css`
  z-index: 1000;
  width: 340px;
  min-height: 500px;
  position: absolute;
  top: 115px;
  background: var(--mini-profile-background-color);
  border: var(--mini-profile-border); //1px solid #605d62;
  box-shadow: var(--mini-profile-box-shadow); //0 4px 20px rgba(101, 119, 136, 0.2);
  border-radius: 20px;

  &:not(.open) {
    transition: right 0.3s 0.05s, opacity 0.3s;
    right: -360px;
    opacity: 0;
  }

  &.open {
    transition: right 0.3s, opacity 0.3s 0.1s;
    right: 50px;
    opacity: 1;
  }

  .avatar {
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    width: 130px;
    height: 130px;
    border-radius: 50%;
    border: var(--mini-profile-avatar-border);
  }

  .user-name {
    font-weight: 600;
    font-size: 23px;
    line-height: 27px;
    text-align: center;
    margin-top: 20px;
  }

  .manage-profile-button {
    padding: 15px 25px;
    border: 1px solid #bfbfbf;
    border-radius: 145px;
    margin: 55px;
    font-weight: 500;
    font-size: 23px;
    line-height: 27px;
    text-align: center;
    cursor: pointer;
  }

  .actions {
    margin: 30px 35px;
    display: flex;
    flex-direction: column;
    gap: 25px;
    align-items: flex-start;
    .action {
      &.logout-action {
        cursor: pointer;
      }
      font-style: normal;
      font-weight: 500;
      font-size: 23px;
      line-height: 27px;
      display: flex;
      gap: 20px;
    }
  }
  .language-section {
    text-align: center;
    display: flex;
    justify-content: center;
    font-size: 1.3rem;
    padding: 10px 0;
  }
`;
