import { http } from 'connex-cds';

export default {
  login: credentials => http.post({ apiName: 'authnz', path: '/login', data: credentials }),
  sendCode: data => http.post({ apiName: 'authnz', path: '/send-code', data }),
  changePassword: data => http.post({ apiName: 'authnz', path: `/change-password`, data }),
  updatePassword: ({ profileRef, data }) =>
    http.patch({ apiName: 'authnz', path: `/profiles/${profileRef}/credentials/password`, data }),
  forgetMe: ({ profileRef, data }) => http.post({ apiName: 'authnz', path: `/profiles/${profileRef}/forget`, data }),
  getProfileAppEntity: ({ profileRef, appRef, entityRef }) =>
    http.get({ apiName: 'authnz', path: `/profiles/${profileRef}/apps/${appRef}/entities/${entityRef}/permissions` }),
};
