import styled from '@emotion/styled';
import { Form, Localization } from 'connex-cds';
import { get } from 'lodash';
import React from 'react';
import getPath from '../copied-from-sdk/getPath';
import { useIsFieldAllowed } from '../copied-from-sdk/useIsFieldAllowed';
import { coerceValue } from '../copied-from-sdk/util';
import { Input } from './mui-input/Input';
import style from './style';

const Styled = styled.div`
  ${style}
`;

export const ExampleCustomComponent = React.forwardRef((props, ref) => {
  const formContext = Form.useFormContext();
  const isFieldAllowed = useIsFieldAllowed();
  const { isSubmitting, setFieldValue, disabled, handleBlur, values } = formContext;
  const { fieldConfig, isRequired, rows, multiline, validationText = true, ...otherProps } = props;
  const dataTestId = { 'data-testid': `${fieldConfig.testId}-form-field` };

  const handleInputChange = React.useCallback(
    e => {
      const value = e.target.value;

      const transformedValue = fieldConfig?.transformOut?.(value);
      setFieldValue(fieldConfig.path, transformedValue || coerceValue({ type: fieldConfig.dataType, value }));
    },

    [fieldConfig, setFieldValue]
  );

  const value = React.useMemo(() => {
    const path = getPath(fieldConfig);
    const incomingValue = get(values, path);
    return fieldConfig?.transformIn ? fieldConfig?.transformIn?.(incomingValue) : incomingValue;
  }, [fieldConfig, values]);

  const translateMessage = Localization.useTranslateMessage();

  const label = React.useMemo(
    () => translateMessage(fieldConfig.labelStringId),
    [fieldConfig.labelStringId, translateMessage]
  );

  if (!isFieldAllowed(fieldConfig)) return null;

  return (
    <Styled className="example-custom-component" {...dataTestId}>
      <Input
        name={fieldConfig.path}
        label={label}
        data-testid={fieldConfig.testId}
        ref={ref}
        value={value}
        onBlur={handleBlur}
        disabled={disabled || isSubmitting}
        type={fieldConfig.password ? 'password' : 'text'}
        testId={fieldConfig.testId}
        {...otherProps}
        onChange={handleInputChange}
      />
    </Styled>
  );
});
