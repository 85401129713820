import { Core, Form, Localization, Typography } from "connex-cds";
import React from 'react';
import { useNavigate } from "react-router-dom";
import styled from 'styled-components';
import cn from 'classnames';

const Styled = styled.div`
  .instructions {
    margin-bottom: 25px;
  }
  .buttons {
    display: flex;
    & > * {
      &:first-child {
        flex: 1;
      }
    }
  }
`;
export const StepTwoForm = () => {
  const {
    Components: { Code, Password, PasswordVerify, SubmitButton },
  } = Form.useFormContext();
  const navigate = useNavigate();

  return (
    <Styled className={cn('forgot-password-form')}>
      <div className="instructions">
        <Typography.Helper>
          <Localization.Translate stringId="forgot-password-instructions" />
        </Typography.Helper>
      </div>
      <Code />
      <Password />
      <PasswordVerify />
      <div className="instructions">
        <Typography.Helper>
          <Localization.Translate stringId="password-requirements" />
        </Typography.Helper>
      </div>
      <div className="buttons">
        <div>
          <Core.Button
            stringId="Cancel"
            data-testid="cancel-button"
            onClick={() => {
              navigate('/login');
            }}
          />
        </div>
        <SubmitButton stringId="save-password" testId="save-password-button" disabledWhenNotDirty={false} />
      </div>
    </Styled>
  );
};
