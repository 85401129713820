import cn from 'classnames';
import { Core, Form, Drawer } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';

const Styled = styled.div`
  .actions {
    margin-top: 20px;
    display: flex;
    .left {
      display: flex;
      flex: 1;
      justify-content: flex-start;
    }
    .right {
      display: flex;
      flex: 1;
      justify-content: flex-end;
    }
  }
`;

export const ChangePasswordForm = () => {
  const { Components } = Form.useFormContext();
  const { closeDrawer } = Drawer.useDrawerContext();

  return (
    <Styled className={cn('change-password-form')}>
      <Components.OldPassword />
      <Components.NewPassword />
      <Components.NewPasswordVerify />
      <div className="actions">
        <div className="left">
          <Core.Button onClick={closeDrawer} stringId="cancel" data-testid="cancel-button" />
        </div>
        <div className="right">
          <Components.SubmitButton
            stringId="changePassword"
            data-testid="submit-password-change-button"
            disabledWhenNotDirty={false}
          />
        </div>
      </div>
    </Styled>
  );
};
