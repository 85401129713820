import cn from 'classnames';
import { Form, Localization } from 'connex-cds';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const Styled = styled.div`
  .buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    & > * {
      &:first-child {
        flex: 1;
      }
    }
  }
`;

const LoginForm = () => {
  const { values, Components } = Form.useFormContext();
  const navigate = useNavigate();
  const { Username, Password, SubmitButton } = Components;

  const handleForgotClick = React.useCallback(() => {
    navigate('/forgotPassword', { state: { username: values?.credentials?.username } });
  }, [navigate, values?.credentials?.username]);

  return (
    <Styled className={cn('login')}>
      <div>
        <Username />
      </div>
      <div>
        <Password />
      </div>
      <div className="buttons">
        <a onClick={handleForgotClick} data-testid="forgot-password-link">
          <Localization.Translate stringId="forgotPassword" data-testid="forgot-password-text" />
        </a>
        <SubmitButton stringId="login" testId="login-button" />
      </div>
    </Styled>
  );
};

export default LoginForm;
