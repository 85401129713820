import React from 'react';
import { Outlet } from 'react-router-dom';

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  render() {
    if (this.state.hasError) {
      return <h3>{this.state.error?.message}</h3>;
    }

    return <Outlet />;
  }
}
