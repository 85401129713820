import { useEntityContext, User, useAppContext } from 'connex-cds';
import React from 'react';
import { auth } from '../api';

export const useProfileAppEntitySettings = () => {
  const { user } = User.useUserContext();
  const { appRef } = useAppContext();
  const { entityRef } = useEntityContext();

  React.useEffect(() => {
    if (user?.profileRef && appRef && entityRef) {
      auth.getProfileAppEntity({ profileRef: user?.profileRef, appRef, entityRef }).then(response => {
        console.log('response', response);
      });
    }
  }, [user?.profileRef, appRef, entityRef]);

  return {};
};
