import { Core, Form } from 'connex-cds';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import cn from 'classnames';

const Styled = styled.div``;
export const StepOneForm = () => {
  const { Components } = Form.useFormContext();
  const navigate = useNavigate();

  return (
    <Styled className={cn('step-one-form')}>
      <div>
        <Components.Username />
        <div className="buttons" style={{ display: 'flex' }}>
          <div style={{ flex: '1' }}>
            <Core.Button
              stringId="Cancel"
              data-testid="cancel-button"
              onClick={() => {
                navigate('/login');
              }}
            />
          </div>
          <Components.SubmitButton stringId="requestReset" disabledWhenNotDirty={false} />
        </div>
      </div>
    </Styled>
  );
};
