import { Form } from 'connex-cds';

export const formConfig = {
  fields: [
    {
      path: 'username',
      name: 'username',
      labelStringId: 'username',
      dataType: Form.FIELD_TYPES.string,
      initialFocus: true,
      testId: 'username',
    },
  ],
};
