import { css } from 'styled-components';

export default css`
  --pill-button-border: 1px solid #bfbfbf;
  width: 50px;
  height: 50px;
  overflow: hidden;
  margin-bottom: 35px;
  margin-left: 25px;
  transition: width 0.3s;
  cursor: pointer;
  .theme-outer {
    overflow: hidden;
    border: var(--pill-button-border);
    border-radius: 25px;
    height: 50px;
    .theme-inner {
      display: flex;
      height: 50px;
      align-items: center;
      position: relative;
      .icon-section {
        width: 50px;
        //margin-left: 10px;
        font-size: 30px;
        .icon {
          position: absolute;
          top: 1px;
          left: 9px;
          transition: opacity 0.3s;
        }
      }
      width: 260px;
    }
  }

  &.light {
    .theme-outer {
      .theme-inner {
        .icon-section {
          .icon {
            &.light {
              opacity: 1;
            }
            &.dark {
              opacity: 0;
            }
          }
        }
      }
    }
  }

  &.dark {
    .theme-outer {
      .theme-inner {
        .icon-section {
          .icon {
            &.light {
              opacity: 0;
            }
            &.dark {
              opacity: 1;
            }
          }
        }
      }
    }
  }

  &.open {
    width: 225px;
    .theme-outer {
      .theme-inner {
        display: flex;
      }
    }
  }
`;
