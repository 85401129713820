import { Form } from 'connex-cds';

export default {
  fields: [
    {
      path: 'email',
      labelStringId: 'email',
      dataType: Form.FIELD_TYPES.string,
      testId: 'email',
    },
    {
      path: 'phone',
      name: 'phoneNumber',
      labelStringId: 'phoneNumber',
      dataType: Form.FIELD_TYPES.string,
      testId: 'phoneNumber',
    },
    {
      path: 'firstName',
      labelStringId: 'firstName',
      dataType: Form.FIELD_TYPES.string,
      testId: 'firstName',
    },
    {
      path: 'lastName',
      labelStringId: 'lastName',
      dataType: Form.FIELD_TYPES.string,
      testId: 'lastName',
    }
  ],
};
