import React from 'react';
import { useQuery } from 'react-query';
import Apps from '../apps';

const defaultOptions = {
  // staleTime: 60000,
};

export const useProfileAppsQuery = ({ options, profileRef }) => {
  const opts = React.useMemo(() => ({ ...defaultOptions, ...options }), [options]);
  return useQuery({
    queryKey: ['profile-apps', profileRef],
    queryFn: Apps.listUserApps,
    ...opts,
    enabled: !!profileRef,
  });
};
