import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { Core, Form, Localization } from 'connex-cds';
import { auth } from '../../../../api';
const { pick } = require('lodash');

const { useFormContext } = Form;

const Styled = styled.div``;
export const Password = () => {
  const {
    Components: { Password, NewPassword, NewPasswordVerify },
    isValid,
    values,
  } = useFormContext();

  const handleSaveClick = React.useCallback(async () => {
    const credentials = pick(values, ['firstName', 'lastName', 'Password', 'newPassword']);
    const response = await auth.updatePassword(credentials);
    return response;
  }, [values]);

  return (
    <Styled className={cn('password')}>
      <pre className={'password-requirements'}>
        <Localization.Translate stringId={'passwordRequirements'} data-testid={'passwordRequirements'} />
      </pre>
      <Password />
      <NewPassword />
      <NewPasswordVerify />
      <Core.Button
        type="primary"
        data-testid="save-button"
        stringId="save"
        onClick={handleSaveClick}
        disabled={!isValid}
      />
    </Styled>
  );
};
