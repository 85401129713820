import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const DefaultLandingPage = () => {
  const location = useLocation();

  const destination = React.useMemo(() => {
    // TODO: Determine where to send the user.  Perhaps the user's preferred starting location, their last known position, etc.
  }, [location]);

  return <div className="default-landing-page" />;
};

export default DefaultLandingPage;
