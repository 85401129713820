import { API, AppConnexDesignSystem, useApiCall } from 'connex-cds';
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { auth } from './api';
import messages from './i18n';
import { BaseRoutes } from './routes/BaseRoutes';

API.setSource('connex');

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => {
  const login = useApiCall(auth.login);

  return (
    <BrowserRouter>
      <AppConnexDesignSystem onLogin={login} messages={messages} initialTimeZone="America/Chicago">
        <QueryClientProvider client={queryClient}>
          <BaseRoutes />
        </QueryClientProvider>
      </AppConnexDesignSystem>
    </BrowserRouter>
  );
};

export default App;
