import { useQuery } from 'react-query';
import { useAppContext, User } from 'connex-cds';
import { find } from 'lodash';
import React from 'react';
import { apps } from '../../api';
import { useAppsEntitiesQuery } from '../../api/query-hooks/useAppEntitiesQuery';
import { useProfileAppsQuery } from '../../api/query-hooks/useProfileAppsQuery';

export const MonitorApp = () => {
  const { appRef, setAppConfig, setAppEntities } = useAppContext();

  const userContext = User.useUserContext();
  const { user, userApps, setUserApps, setUserAppsIsLoading } = userContext;

  const appsQuery = useQuery({ queryKey: ['apps'], queryFn: apps.listApps, staleTime: Infinity });

  const userAppsQuery = useProfileAppsQuery({
    profileRef: user.profileRef,
    options: { initialData: { apps: userApps || [] } },
  });

  React.useEffect(() => {
    setUserApps(userAppsQuery?.data?.apps);
    setUserAppsIsLoading(userAppsQuery.isLoading || userAppsQuery.isFetching);
  }, [setUserApps, setUserAppsIsLoading, userAppsQuery?.data, userAppsQuery.isFetching, userAppsQuery.isLoading]);

  React.useEffect(() => {
    if (!appRef || !appsQuery.isSuccess) return;
    const appConfig = find(appsQuery.data, { crn: appRef });
    setAppConfig(appConfig);
  }, [appRef, appsQuery.data, appsQuery.isSuccess, setAppConfig]);

  const appEntitiesQuery = useAppsEntitiesQuery({ profileRef: user?.profileRef, appRef });

  React.useEffect(() => {
    if (['admin', 'settings'].includes(appRef)) {
      setAppEntities(undefined);
    } else {
      setAppEntities(appEntitiesQuery?.data?.entities);
    }
  }, [appEntitiesQuery?.data?.entities, appRef, setAppEntities, user.entities]);

  return null;
};
