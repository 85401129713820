import { Auth } from 'connex-cds';
import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { ProductionWarning } from '../authenticated-layout/header/ProductionWarning';
import { MonitorApp } from './MonitorApp';
import { MonitorEntity } from './MonitorEntity';

const { LoginAuthenticated } = Auth;

const AuthenticatedRoutes = () => {
  const location = useLocation();

  return (
    <LoginAuthenticated toLogin={<Navigate to="/login" state={{ from: location }} replace />}>
      <MonitorApp />
      <MonitorEntity />
      <ProductionWarning />
      <Outlet />
    </LoginAuthenticated>
  );
};

export default AuthenticatedRoutes;
