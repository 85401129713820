import { css } from 'styled-components';
import { calculateAppGridHeight } from '../style';

export default css`
  padding: 16px;
  height: ${({ $apps }) => `${calculateAppGridHeight($apps)}px`};
  overflow: auto;
  .app-icons-inner {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;

    .app-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 69px;
      height: 69px;
      background-color: var(--sidebar-menu-icon-background-color-active);
      border-radius: 15px;
      cursor: pointer;
      font-size: 12px;
      padding: 3px;
    }
  }
`;
