import cn from 'classnames';
import { Auth, Localization, useEntityContext, User, useAppContext } from 'connex-cds';
import React from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import style from './style';

const Styled = styled.div`
  ${style}
`;

export const ProfileSlideOut = ({ className, onClose }) => {
  const { logout } = Auth.useAuthContext();
  const { user, setUser, setUserApps } = User.useUserContext();
  const navigate = useNavigate();
  const { reset, setEntityRef } = useEntityContext();
  const { setAppRef } = useAppContext();
  const queryClient = useQueryClient();

  const handleLogout = React.useCallback(() => {
    queryClient.invalidateQueries();
    setEntityRef(undefined);
    setUser(undefined);
    setUserApps(undefined);
    logout();
    reset();
  }, [logout, queryClient, reset, setEntityRef, setUser, setUserApps]);

  const handleProfileClick = React.useCallback(() => {
    setAppRef('settings');
    navigate('/app/settings');
    onClose();
  }, [navigate, onClose, setAppRef]);

  return (
    <Styled className={cn('profile-slide-out', className)}>
      <div className="avatar" />
      <div className="user-name">{`${user?.firstName} ${user?.lastName}`}</div>
      <div className="manage-profile-button" onClick={handleProfileClick}>
        Manage Profile
      </div>
      <hr />
      <div className="actions">
        <div className="logout-action action" onClick={handleLogout}>
          <div className="icon">
            <i className="fa-regular fa-arrow-right-from-bracket" />
          </div>
          <div className="label">Logout</div>
        </div>
      </div>
      <hr />
      <div className="language-section">
        <Localization.LocaleSwitcher />
      </div>
    </Styled>
  );
};
