import { User } from 'connex-cds';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { userProfile } from '../api';

export const useProfile = () => {
  const { user } = User.useUserContext();

  return useQuery({
    queryKey: ['profile', user.profileRef],
    queryFn: () => userProfile.getUserProfile(user.profileRef),
    refetchOnWindowFocus: false,
  });
};

export const useUpdateProfile = () => {
  const { user } = User.useUserContext();
  const queryClient = useQueryClient();

  const queryKey = ['profile', user.profileRef];

  const mutation = useMutation({
    mutationFn: profile => userProfile.updateUserProfile({ profileRef: user.profileRef, profile }),
    onMutate: async newProfile => {
      await queryClient.cancelQueries(queryKey);
      const previousItem = queryClient.getQueryData(queryKey);
      queryClient.setQueryData(queryKey, newProfile);
      return { previousItem, newProfile };
    },
    onError: (err, newItem, context) => {
      queryClient.setQueryData(queryKey, context.previousItem);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  return mutation.mutateAsync;
};
