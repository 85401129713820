import { Form } from 'connex-cds';
import * as yup from 'yup';

import YupPassword from 'yup-password';
YupPassword(yup);

export const formConfig = {
  validationSchema: yup.object().shape({
    code: yup.string().required('required'),
    password: yup
      .string()
      .password()
      .min(8, 'passwordMinCharacters')
      .max(99, 'passwordMaxCharacters')
      .minLowercase(1, 'passwordLowerCase')
      .minUppercase(1, 'passwordUpperCase')
      .minNumbers(1, 'passwordNumber')
      .minSymbols(1, 'passwordSpecialCharacter')
      .required('required'),
    passwordVerify: yup
      .string()
      .oneOf([yup.ref('password'), null], 'passwordsDontMatch')
      .required('required'),
  }),
  fields: [
    {
      path: 'code',
      name: 'code',
      labelStringId: 'code',
      dataType: Form.FIELD_TYPES.string,
      initialFocus: true,
      testId: 'forgot-password-code',
    },
    {
      path: 'password',
      labelStringId: 'password',
      dataType: Form.FIELD_TYPES.string,
      password: true,
      testId: 'forgot-password-password',
    },
    {
      path: 'passwordVerify',
      labelStringId: 'passwordVerify',
      dataType: Form.FIELD_TYPES.string,
      password: true,
      testId: 'forgot-password-password-verify',
    },
  ],
};
