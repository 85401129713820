import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { ProfileSlideOut } from '../header/profile-slide-out/ProfileSlideOut';
import { Editor } from './Editor';
import style from './style.js';

const Styled = styled.div`
  ${style}
`;

export const Profile = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const handleClick = React.useCallback(() => {
    setIsOpen(s => !s);
  }, []);

  React.useEffect(() => {
    const handler = e => {
      if (isOpen && e.key === 'Escape') {
        setIsOpen(false);
      }
    };
    window.addEventListener('keydown', handler);
    return () => {
      window.removeEventListener('keydown', handler);
    };
  }, [isOpen]);

  return (
    <Styled className={cn('profile')}>
      <div className={cn('overlay', { open: isOpen })} onClick={() => setIsOpen(false)} />
      <div className="icon" onClick={handleClick}>
        <i className="fa-solid fa-user" />
      </div>
      <ProfileSlideOut
        className={cn({ open: isOpen })}
        onClose={() => {
          setIsOpen(false);
        }}
      />
    </Styled>
  );
};
