import { Form } from 'connex-cds';
import * as yup from 'yup';
import YupPassword from 'yup-password';
YupPassword(yup);

export const formConfig = {
  validationSchema: yup.object().shape({
    password: yup.string().required('required'),
    newPassword: yup
      .string()
      .password()
      .notOneOf([yup.ref('password'), null], 'passwordsCantMatch')
      .min(8, 'passwordMinCharacters')
      .max(99, 'passwordMaxCharacters')
      .minLowercase(1, 'passwordLowerCase')
      .minUppercase(1, 'passwordUpperCase')
      .minNumbers(1, 'passwordNumber')
      .minSymbols(1, 'passwordSpecialCharacter')
      .required('required'),
    newPasswordVerify: yup
      .string()
      .oneOf([yup.ref('newPassword'), null], 'passwordsDontMatch')
      .required('required'),
  }),
  fields: [
    {
      path: 'firstName',
      labelStringId: 'firstname',
      dataType: Form.FIELD_TYPES.string,
      testId: 'profile-firstname',
    },
    {
      path: 'lastName',
      labelStringId: 'lastname',
      dataType: Form.FIELD_TYPES.string,
      testId: 'profile-lastname',
    },
    {
      path: 'email',
      labelStringId: 'email',
      dataType: Form.FIELD_TYPES.string,
      testId: 'profile-email',
    },
    {
      path: 'settings.mobilePhone',
      name: 'mobilePhoneNumber',
      labelStringId: 'mobilePhone',
      dataType: Form.FIELD_TYPES.string,
      testId: 'profile-mobilePhone',
    },
    {
      path: 'password',
      labelStringId: 'currentPassword',
      dataType: Form.FIELD_TYPES.string,
      password: true,
      testId: 'profile-currentPassword',
    },
    {
      path: 'newPassword',
      labelStringId: 'newPassword',
      dataType: Form.FIELD_TYPES.string,
      password: true,
      testId: 'profile-newPassword',
    },
    {
      path: 'newPasswordVerify',
      labelStringId: 'newPasswordVerify',
      dataType: Form.FIELD_TYPES.string,
      password: true,
      testId: 'profile-newPasswordVerify',
    },
  ],
};
