import { css } from 'styled-components';

// TODO: theming

const GRID_WIDTH = 3;
const GAP = 16;
const TOP_MARGIN = 16;
const BOTTOM_MARGIN = 16;
const TITLE_HEIGHT = 71;
const APP_ICON_HEIGHT = 69;
const MAX_VISIBLE_ROWS = 3;

const calculateAppTrayHeight = apps => {
  const numberOfApps = apps?.length;
  const numberOfRows = Math.ceil(numberOfApps / GRID_WIDTH);
  const numberOfHiddenRows = numberOfRows - MAX_VISIBLE_ROWS;

  const gaps = (numberOfRows - 1) * GAP;

  const hiddenRowsHeight = numberOfHiddenRows * APP_ICON_HEIGHT + (numberOfHiddenRows - 1) * GAP;

  const fullHeight = numberOfRows * APP_ICON_HEIGHT + gaps + TITLE_HEIGHT + TOP_MARGIN + BOTTOM_MARGIN;
  const constrainedHeight = fullHeight - hiddenRowsHeight - BOTTOM_MARGIN;

  return Math.min(fullHeight, constrainedHeight);
};

export const calculateAppGridHeight = apps => {
  return calculateAppTrayHeight(apps) - TITLE_HEIGHT;
};

export default css`
  &&& {
    .header-icon {
      position: relative;
      .app-tray {
        z-index: 10;
        position: absolute;
        left: -105px;
        top: 86px;
        border: var(--mini-profile-border);
        border-radius: 20px;

        height: 0;
        opacity: 0;
        overflow: hidden;

        transition: opacity 0.3s, height 0.3s;
        background-color: var(--sidebar-background-color);
        box-shadow: var(--mini-profile-box-shadow);
        &.active {
          height: ${({ $apps }) => `${calculateAppTrayHeight($apps)}px`};
          opacity: 1;
        }

        .spinner {
          margin-top: 20px;
        }

        .tray-title {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 70px;
          border-bottom: 1px solid var(--mini-profile-border);

          .tray-title-text {
            font-weight: 600;
            font-size: 23px;
            line-height: 27px;
            color: var(--sidebar-menu-icon-color);
          }
        }

        .app-tray-inner {
          width: 279px;
        }
      }
    }

    .overlay {
      &:not(.open) {
        display: none;
      }
      opacity: 0;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
    }
  }
`;
