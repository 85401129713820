import { API, useEntityContext, User, useAppContext } from 'connex-cds';
import React from 'react';
import { useQuery } from 'react-query';
import AppEntities from '../../api/app-entities';
import decode from 'jwt-decode';

export const MonitorEntity = () => {
  const { entityRef, setToken } = useEntityContext();
  const { appRef } = useAppContext();
  const { user, setRole } = User.useUserContext();

  const query = useQuery({
    queryKey: ['app-entity-settings', { profileRef: user?.profileRef, appRef, entityRef }],
    queryFn: AppEntities.getAppEntitySettings,
    enabled: !!(user?.profileRef && appRef && entityRef),
  });

  React.useEffect(() => {
    const token = query?.data?.access_token;
    if (token) {
      API?.setAppEntityToken?.(token);
      setToken({ entityRef, value: token });

      const decoded = decode(token);

      const permissions = decoded?.cai?.permissions?.map?.(p => p.replace(`${decoded?.aud?.[0]}:`, ''));

      setRole({ permissions });
    }
  }, [entityRef, query?.data, setRole, setToken]);

  return null;
};
