import cn from 'classnames';
import React from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import Header from './header/Header';
import { Sidebar } from './sidebar/Sidebar';
import style from './style';

const Styled = styled.div`
  ${style}
`;

const AuthenticatedLayout = () => {
  return (
    <Styled className={cn('authenticated-layout')}>
      <Sidebar>
        <Header />
        <Outlet />
      </Sidebar>
    </Styled>
  );
};

export default AuthenticatedLayout;
