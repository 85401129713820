import { Form, Layout } from 'connex-cds';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useChangePassword } from '../useChangePassword';
import { DisplayError } from './DisplayError';
import { formConfig } from './form-config';
import { StepTwoForm } from './StepTwoForm';

export const StepTwo = () => {
  const { changePassword } = useChangePassword();
  const location = useLocation();
  const navigate = useNavigate();
  const [error, setError] = React.useState();

  const handleSubmit = React.useCallback(
    async val => {
      const { token, username } = location?.state;
      const { code, password } = val;

      setError(undefined);

      const response = await changePassword({ username, token, code, password });

      console.log('response', response);
      
      if (response.error) {
        setError(response.error);
        return;
      }

      navigate('/login', { state: { message: { stringId: 'password-reset-success', severity: 'success' } } });

      return response;
    },
    [changePassword, location?.state, navigate]
  );

  return (
    <div className="step-two" style={{ position: 'relative', margin: 'auto', minWidth: '350px' }}>
      <DisplayError error={error} />
      <Layout.Container style={{ maxWidth: '450px' }}>
        <Form.FormProvider onSubmit={handleSubmit} config={formConfig}>
          <StepTwoForm />
        </Form.FormProvider>
      </Layout.Container>
    </div>
  );
};
