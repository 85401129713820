import { Layout } from 'connex-cds';
import React from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { LinkSection } from '../login/LinkSection';
import { StepOne } from './step-one/StepOne';
import { StepTwo } from './step-two/StepTwo';

const { Column, Container } = Layout;

export const ForgotPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [busy, setBusy] = React.useState(false);

  const handleSavePassword = React.useCallback(() => {
    setBusy(true);
    setTimeout(() => {
      setBusy(false);
      navigate('/');
    }, 1000);
  }, [navigate]);

  return (
    <Column className="content" style={{ height: '100%' }}>
      <Routes>
        <Route path="" element={<StepOne />} />
        <Route path="/verify" element={<StepTwo />} />
      </Routes>
      <LinkSection />
    </Column>
  );
};
