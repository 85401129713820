import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { Auth, Core, Form } from 'connex-cds';
import { useUserProfile } from '../useUserProfile';

const { useFormContext } = Form;
const { useAuthContext } = Auth;

const Styled = styled.div``;
export const General = () => {
  const {
    Components: { FirstName, LastName, Email, MobilePhoneNumber },
    values,
  } = useFormContext();

  const { updateUserData, busy } = useUserProfile();

  const { logout } = useAuthContext();

  const mockProfileRef = 'edd65c4b-6cfd-4d0b-a5b4-e3c29b43aa43';

  const handleLogoutClick = React.useCallback(() => {
    logout();
  }, [logout]);

  const handleSaveClick = React.useCallback(async () => {
    updateUserData({ profileRef: mockProfileRef, userData: values });
  }, [updateUserData, values]);

  return (
    <Styled className={cn('general')}>
      <FirstName />
      <LastName />
      <Email />
      <MobilePhoneNumber />
      <div className="actions" style={{ padding: '15px' }}>
        <Core.Button
          type="primary"
          data-testid="save-button"
          stringId="save"
          loading={busy}
          onClick={handleSaveClick}
        />
      </div>
      <div className="logout">
        <Core.Button type="primary" onClick={handleLogoutClick} stringId="logout" data-testid="logout-button" />
      </div>
    </Styled>
  );
};
