import cn from 'classnames';
import { isObject } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { Core, Layout, Responsive, usePostMessageContext } from 'connex-cds';
import { usePostMessageListener } from '../util/usePostMessageListener';
import FourG from './andoid-assets/4G.png';
import battery from './andoid-assets/battery.png';
import muted from './andoid-assets/muted.png';

const Styled = styled.div`
  &.trackit-container {
    flex: 1;
    &.simulate {
      margin-top: 25px;
      margin-left: auto;
      margin-right: auto;
      //height: 621px;
      flex: 0;
      &.isMobile {
        iframe.trackIt {
          height: 821px;
          width: 390px;
          border: 1px solid #666;
        }
        trackit-header {
          display: none;
        }
      }
    }

    .android-header {
      background-color: black;
      height: 23px;
      display: flex;
      .time {
        flex: 1;
        color: #fff;
        padding-left: 20px;
      }
      .icons {
        display: flex;
        img {
          margin-left: 10px;
        }
        padding-right: 20px;
      }
    }
    .trackit-header {
      background-color: #12415b;
      color: #fdffff;
      height: 67px;
      display: flex;
      align-items: center;
      .left {
        flex: 1;
        padding-left: 40px;
        font-size: 40px;
        display: flex;
        align-items: center;
        gap: 30px;
        .name {
          font-size: 25px;
        }
      }
      .right {
        display: flex;
        align-items: center;
        gap: 20px;
        padding-right: 25px;
        .date-time {
          font-size: 25px;
          color: #fff;
        }
        .icon {
          font-size: 40px;
          color: #6597b6;
        }
      }
    }
    .trackit-footer {
      background-color: #ffffff;
      height: 33px;
      border-top: 1px solid #ddd;
    }

    .dev-tools-section {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      .events {
        display: flex;
        gap: 20px;
      }
    }
  }
`;

const options = [
  { id: 'PRINTED', label: 'PRINTED' },
  { id: 'LOADING_STARTED', label: 'LOADING_STARTED' },
  { id: 'LOADING_COMPLETE', label: 'LOADING_COMPLETE' },
  { id: 'TO_JOB', label: 'TO_JOB' },
  { id: 'ARRIVE_JOB', label: 'ARRIVE_JOB' },
  { id: 'UNLOADING', label: 'UNLOADING' },
  { id: 'END_UNLOADING', label: 'END_UNLOADING' },
  { id: 'LEAVE_JOB', label: 'LEAVE_JOB' },
  { id: 'IN_YARD', label: 'IN_YARD' },
  { id: 'WASHING', label: 'WASHING' },
  { id: 'END_WASHING', label: 'END_WASHING' },
  { id: 'PUMPING_STARTED', label: 'PUMPING_STARTED' },
  { id: 'PUMPING_COMPLETE', label: 'PUMPING_COMPLETE' },
  { id: 'READY_TO_PUMP', label: 'READY_TO_PUMP' },
];

export const SimulateTrackit = ({ children, simulate }) => {
  const { sendMessage } = usePostMessageContext();
  const [isOnline, setIsOnline] = React.useState(true);
  const [currentEvent, setCurrentEvent] = React.useState();
  const [crn, setCrn] = React.useState();
  const [isMobile, setIsMobile] = React.useState(false);

  const handleMobileToggle = React.useCallback(() => {
    setIsMobile(s => !s);
  }, []);

  const handleOnlineToggle = React.useCallback(
    val => {
      setIsOnline(val);
      sendMessage({ type: val ? 'online' : 'offline' });
    },
    [sendMessage]
  );

  const handleEventChange = React.useCallback(eventId => {
    setCurrentEvent(eventId);
  }, []);

  const sendEvent = React.useCallback(() => {
    sendMessage({
      type: 'status-event',
      timestamp: new Date().toISOString(),
      name: currentEvent,
    });
  }, [currentEvent, sendMessage]);

  const handleCancelTicket = React.useCallback(() => {
    sendMessage({
      type: 'ticket',
      active: false,
    });
  }, [sendMessage]);

  const handleSendTicket = React.useCallback(() => {
    sendMessage({
      type: 'ticket',
      active: true,
      crn,
      number: 'asdf',
    });
  }, [crn, sendMessage]);

  const listener = React.useCallback(
    event => {
      let message;

      if (isObject(event?.data)) {
        message = event?.data;
      } else {
        try {
          message = JSON.parse(event?.data);
        } catch (e) {
          message = event?.data;
        }
      }

      if (message?.type === 'submit') {
        sendMessage({ type: 'ticket', active: false });
      }
    },
    [sendMessage]
  );

  usePostMessageListener(listener);

  return (
    <Styled className={cn('trackit-container', { simulate, isMobile })}>
      {simulate && (
        <>
          <div className="android-header">
            <div className="time">10:10</div>
            <div className="icons">
              <img src={FourG} height="22" />
              <img src={muted} height="22" />
              <img src={battery} height="22" />
            </div>
          </div>
          {isMobile ? null : (
            <div className="trackit-header">
              <div className="left">
                <i className="fa-sharp fa-solid fa-arrow-left" />
                <div className="name">Demo User</div>
              </div>
              <div className="right">
                <i className="icon fa-solid fa-tower-cell" />
                <div className="date-time">10:10 | Sep 16</div>
                <i className="icon fa-sharp fa-solid fa-house" />
              </div>
            </div>
          )}
        </>
      )}
      {children}
      {simulate && !isMobile && <div className="trackit-footer"></div>}
      {simulate && (
        <Layout.Container className="dev-tools-section">
          <div style={{ display: 'flex', gap: '20px' }}>
            <Core.Switch
              onChange={handleOnlineToggle}
              checkedStringId="online"
              uncheckedStringId="offline"
              data-testid="online-toggle"
              value={isOnline}
            />
            <Core.Switch
              onChange={handleMobileToggle}
              checkedStringId="Mobile"
              uncheckedStringId="Desktop"
              data-testid="mobile-toggle"
              value={isMobile}
            />
          </div>
          <div className="events">
            <Core.Select options={options} onChange={handleEventChange} value={currentEvent} />
            <Core.Button stringId="Send Event" data-testid="send-event" onClick={sendEvent} disabled={!currentEvent} />
          </div>
          <Core.Button stringId="Cancel Ticket" data-testid="cancel-ticket" onClick={handleCancelTicket} />
          <div className="events">
            <Core.Input onChange={setCrn} value={crn} placeholder="Crn" />
            <Core.Button stringId="Send Ticket" data-testid="send-ticket" onClick={handleSendTicket} disabled={!crn} />
          </div>
        </Layout.Container>
      )}
    </Styled>
  );
};
