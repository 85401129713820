import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { useAppsQuery } from '../../../../../api/query-hooks/useAppsQuery';
import style from './style';

const Styled = styled.div`
  ${style}
`;

export const AppIcons = ({ apps, onClick }) => {
  const appsQuery = useAppsQuery();

  const filteredApps = React.useMemo(() => {
    if (!apps?.length || appsQuery.isLoading) return [];

    const caiApps = appsQuery?.data?.filter(app => app.type === 'CX_APP_CAI').map(app => app.crn);

    return apps.filter(app => caiApps?.includes?.(app.appRef)) || [];
  }, [apps, appsQuery?.data, appsQuery.isLoading]);

  return (
    <Styled className={cn('app-icons')} $apps={apps}>
      <div className="app-icons-inner">
        {filteredApps?.map?.(app => (
          <div className="app-icon" onClick={() => onClick(app.appRef)} key={app.appRef}>
            <div>{app.name}</div>
          </div>
        ))}
      </div>
    </Styled>
  );
};
