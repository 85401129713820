import { message } from '../core/message/message';

let _config;
let _source;
let _loginToken;
let _appEntityToken;
let _apiKey;

export const initialize = (optionalServices = {}) => {
  _config = {
    ...optionalServices,
  };

  return Promise.resolve();
};

export const getConfig = apiName => {
  return _config[apiName];
};

export const handleError = msg => {
  message.error(msg);
};

export const setSource = source => {
  _source = source;
};

export const getSource = () => _source;

export const setLoginToken = token => {
  _loginToken = token;
};

export const getLoginToken = () => _loginToken;

export const setAppEntityToken = token => {
  _appEntityToken = token;
};

export const getAppEntityToken = () => _appEntityToken;

export const setApiKey = apiKey => {
  _apiKey = apiKey;
};

export const getApiKey = () => _apiKey;

export const API = {
  initialize,
  getConfig,
  getSource,
  setSource,
  handleError,
  getLoginToken,
  setLoginToken,
  getAppEntityToken,
  setAppEntityToken,
  setApiKey,
  getApiKey,
};
