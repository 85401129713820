import { Form, Localization } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';

const Styled = styled.div`
  .info-section {
    margin-bottom: 20px;
  }
  .actions {
    display: flex;
    justify-content: flex-end;
  }
`;

export const ForgetMeForm = () => {
  const { Components } = Form.useFormContext();

  return (
    <Styled className={cn('forget-me-form')}>
      <div className="info-section">
        <Localization.Translate
          stringId="forget-me-warning"
          data-testid="forget-me-warning"
          values={{ p: value => <p>{value}</p> }}
        />
      </div>
      <Components.Password />
      <div className="actions">
        <Components.SubmitButton
          stringId="forgetMe"
          data-testid="forgetMe-button"
          danger
          disabledWhenNotDirty={false}
        />
      </div>
    </Styled>
  );
};
