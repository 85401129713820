import React from 'react';
import { useQuery } from 'react-query';
import AppEntities from '../app-entities';

const defaultOptions = {
  staleTime: 60000,
};

export const useAppsEntitiesQuery = ({ options, profileRef, appRef }) => {
  const opts = React.useMemo(() => ({ ...defaultOptions, ...options }), [options]);

  return useQuery({
    queryKey: ['app-entities', { profileRef, appRef }],
    queryFn: AppEntities.getAppEntities,
    ...opts,
    enabled: !!(profileRef && appRef),
  });
};
