import { createTheme } from '@mui/material/styles';

const themes = {
  dark: createTheme({
    palette: {
      mode: 'dark',
    },
    components: {
      // Name of the component
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: '10px',
          },
        },
      },
    },
  }),

  light: createTheme({
    palette: {
      mode: 'light',
    },
    typography: {
      fontFamily: [
        'Work Sans',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    },
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          input: {
            // padding: '12px 16px',
          },
          notchedOutline: {
            // '& legend': {
            //   padding: '5px',
            // },
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          outlined: {
            // top: '-4px',
          },
          shrink: {
            // height: '16px',
            // fontFamily: 'Work Sans',
            // fontStyle: 'normal',
            // fontWeight: '600',
            // fontSize: '16px',
            // lineHeight: '16px',
            // top: '0',
            // transform: 'translate(14px, -3px) scale(0.85)',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          containedPrimary: {
            backgroundColor: '#124E88',
          },
          root: {
            textTransform: 'none',
            borderRadius: 20,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '10px 24px',
            gap: '8px',
            height: '40px',
          },
        },
      },
    },
  }),
};

export default themes;
