import { Localization } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';

const Styled = styled.div`
  .dynamic-menu-item-icon {
    width: 275px;
    display: flex;
    gap: 30px;
    align-items: center;
    margin-top: 5px;
  }
`;

export const DynamicMenuItem = ({ item, onClick, className }) => {
  const handleClick = React.useCallback(() => {
    onClick(item.id);
  }, [item.id, onClick]);

  return (
    <Styled
      className={cn('dynamic-menu-item', { disabled: item.disabled }, className)}
      onClick={handleClick}
      key={item.id}
      data-testid={item.id}
    >
      <div className="dynamic-menu-item-icon">
        <div className="icon">{item.icon}</div>
        <div className="label" style={{ width: '225px', overflow: 'hidden' }}>
          {item.label}
        </div>
      </div>
    </Styled>
  );
};
