import React from 'react';
import api from '../../../api/auth';

export const useChangePassword = () => {
  const [isLoading, setIsLoading] = React.useState(false);

  const changePassword = React.useCallback(async ({ username, token, code, password }) => {
    setIsLoading(true);

    const response = await api.changePassword({ username, token, code, password });
    setIsLoading(false);

    return response;
  }, []);

  return { changePassword, isLoading };
};
