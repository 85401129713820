import { omit } from 'lodash';
import queryString from 'query-string';
import { API } from '../api';
import { getApiHostname } from './urlParts';

const endpointString = 'REACT_APP_ENDPOINT_';

export const endpointOverrides = processEnv =>
  Object.keys(processEnv)
    .filter(key => key.startsWith(endpointString))
    .reduce((acc, key) => {
      acc[key.replace(endpointString, '')] = processEnv[key];
      return acc;
    }, {});

export const getApiConfig = apiName => {
  let config;
  let apiHostname;

  const { hostname, port } = window.location;

  if (hostname === 'localhost') {
    if (API.getSource() === apiName) {
      const apiPort = 5000 + +port;
      apiHostname = `${
        endpointOverrides(process.env)[apiName] || `http://localhost:${apiPort}`
      }/v4/services/${apiName}`;
    } else {
      apiHostname = `${
        endpointOverrides(process.env)[apiName] || 'https://api.develop.commandalkon.io'
      }/v4/services/${apiName}`;
    }

    config = {
      id: apiName,
      hostname: apiHostname,
      apiKey: 'ZcHVAa6oQ31uY7xcA4SC47Rq64gaJqN73N7xsO70',
    };
  } else {
    const productionHostname = `https://${getApiHostname(hostname, apiName)}/v4/services/${apiName}`;
    config = {
      id: apiName,
      hostname: productionHostname,
      apiKey: 'ZcHVAa6oQ31uY7xcA4SC47Rq64gaJqN73N7xsO70',
    };
  }

  return config;
};

const getToken = (token, useLoginToken, useEntityToken) => {
  if (token) return token;

  if (useLoginToken) return API.getLoginToken();
  if (useEntityToken) return API.getAppEntityToken();

  return API.getAppEntityToken() || API.getLoginToken();
};

const handleFetch = async ({
  apiName,
  method,
  path = '',
  data = {},
  queryParams = {},
  token: _token,
  useLoginToken = false,
  useEntityToken = false,
}) => {
  const query = queryString.stringify(queryParams);

  const apiConfig = getApiConfig(apiName);

  const token = getToken(_token, useLoginToken, useEntityToken);

  const allOptions = {
    method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-api-key': apiConfig?.apiKey,
      Authorization: `Bearer ${token}`,
      'x-connex-id': token,
    },
    body: method === 'GET' ? undefined : JSON.stringify(data),
  };

  const options = { ...allOptions, headers: omit(allOptions.headers, apiConfig.omitHeaders || []) };

  const response = await fetch(`${apiConfig.hostname}${path}${query ? `?${query}` : ''}`, options);
  // checkVersion(response.headers.get('x-connex-version'));

  if (response.ok) {
    return response.json();
  }

  const error = await response.json();

  API.handleError(error?.issues?.map?.(issue => issue.message)?.join?.(', ') || 'An error has occurred');
  return { error };
};

export const http = {
  post: options => handleFetch({ method: 'POST', ...options }),
  patch: options => handleFetch({ method: 'PATCH', ...options }),
  put: options => handleFetch({ method: 'PUT', ...options }),
  get: options => handleFetch({ method: 'GET', ...options }),
  delete: options => handleFetch({ method: 'DELETE', ...options }),
};
