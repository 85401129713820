import { http } from 'connex-cds';

export default {
  getAppEntities: ({ queryKey }) => {
    const [, { profileRef, appRef }] = queryKey;
    return http.get({ apiName: 'authnz', path: `/profiles/${profileRef}/apps/${appRef}`, useLoginToken: true });
  },
  getAppEntitySettings: ({ queryKey }) => {
    const [, { profileRef, appRef, entityRef }] = queryKey;
    return http.get({
      apiName: 'authnz',
      // path: `/profiles/${profileRef}/apps/${appRef}/entities/${entityRef}`,
      path: `/profiles/${profileRef}/apps/${appRef}/entities/${entityRef}/permissions`,
      useLoginToken: true,
    });
  },
};
