import { ThemeProvider } from '@mui/material/styles';
import { Auth, Core, Form, Layout, Theme } from 'connex-cds';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import themes from "./example-custom-component/themes";
import { formConfig } from './form-config';
import { LinkSection } from './LinkSection';
import LoginForm from './LoginForm';

const { useAuthContext } = Auth;
const { Column, Container } = Layout;
const { FormProvider } = Form;
const { Alert, SEVERITY } = Core;

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { login } = useAuthContext();
  const { themeName } = Theme.useThemeContext();

  const handleLogin = React.useCallback(
    async (...args) => {
      const response = await login(...args);

      if (!response?.error) {
        navigate('/', { replace: true, state: location.state });
      }
    },
    [location.state, login, navigate]
  );

  return (
    <Column className="content" style={{ height: '100%' }}>
      {location?.state?.message?.stringId ? (
        <Alert
          severity={SEVERITY.SUCCESS}
          title={location.state.message?.noTitle ? undefined : 'success'}
          message={location.state.message.stringId}
          closable
          showIcon
        />
      ) : null}
      <FormProvider onSubmit={handleLogin} config={formConfig}>
        <Container style={{ margin: 'auto', minWidth: '350px', padding: '25px' }}>
          <ThemeProvider theme={themes[themeName]}>
            <LoginForm />
          </ThemeProvider>
        </Container>
        <LinkSection />
      </FormProvider>
    </Column>
  );
};

export default Login;
