export default (location, config, entityRef) => {
  const targetPath = `/${location
    ?.split?.('/')
    ?.slice?.(entityRef ? 2 : 1)
    ?.join('/')}`;

  let targetItem;

  const findPath = (items, parent) => {
    items?.forEach?.(item => {
      if (item.items?.length) {
        findPath(item.items, item);
      }

      if (targetPath?.startsWith(item.path)) {
        targetItem = { item, parent };
      }
    });
  };

  findPath(config);

  return targetItem;
};
