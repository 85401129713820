import React from 'react';
import api from '../../../api/auth/index';

export const useRequestCode = () => {
  const [isLoading, setIsLoading] = React.useState(false);

  const requestCode = React.useCallback(async emailOrPhoneNumber => {
    const postData = { username: emailOrPhoneNumber };

    setIsLoading(true);

    const response = await api.sendCode(postData);
    setIsLoading(false);

    return response;
  }, []);

  // return new Promise(resolve => {
  //   setIsLoading(true);
  //
  //   setTimeout(() => {
  //     resolve({
  //       success: true,
  //       details: {
  //         message: 'The code has been sent successfully',
  //       },
  //       data: {
  //         crn: 'c9947e34-cec0-4e51-b804-dcf59540a180',
  //       },
  //     });
  //     setIsLoading(false);
  //   }, 2000);
  // });

  return { requestCode, isLoading };
};
