import { Form } from 'connex-cds';
import * as yup from 'yup';

import YupPassword from 'yup-password';
YupPassword(yup);

export default {
  validationSchema: yup.object().shape({
    oldPassword: yup.string().required('required'),
    newPassword: yup
      .string()
      .password()
      .min(8, 'passwordMinCharacters')
      .max(99, 'passwordMaxCharacters')
      .minLowercase(1, 'passwordLowerCase')
      .minUppercase(1, 'passwordUpperCase')
      .minNumbers(1, 'passwordNumber')
      .minSymbols(1, 'passwordSpecialCharacter')
      .required('required'),
    newPasswordVerify: yup
      .string()
      .oneOf([yup.ref('newPassword'), null], 'passwordsDontMatch')
      .required('required'),
  }),
  fields: [
    {
      path: 'oldPassword',
      name: 'oldPassword',
      labelStringId: 'oldPassword',
      dataType: Form.FIELD_TYPES.string,
      password: true,
      initialFocus: true,
      testId: 'change-password-oldPassword',
    },
    {
      path: 'newPassword',
      labelStringId: 'newPassword',
      dataType: Form.FIELD_TYPES.string,
      password: true,
      testId: 'change-password-newPassword',
    },
    {
      path: 'newPasswordVerify',
      labelStringId: 'newPasswordVerify',
      dataType: Form.FIELD_TYPES.string,
      password: true,
      testId: 'change-password-newPassword-verify',
    },
  ],
};
