import { Form, Layout } from 'connex-cds';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRequestCode } from '../useRequestCode';
import { formConfig } from './form-config';
import { StepOneForm } from './StepOneForm';

export const StepOne = () => {
  const { requestCode } = useRequestCode();
  const navigate = useNavigate();
  const location = useLocation();

  const handleSubmit = React.useCallback(
    async val => {
      const response = await requestCode(val?.username);
      if (response?.success && response?.access_token) {
        navigate('verify', { state: { username: val?.username, token: response?.access_token } });
      }
    },
    [navigate, requestCode]
  );

  const initialValues = React.useMemo(() => ({ username: location?.state?.username }), [location?.state?.username]);

  return (
    <Layout.Container style={{ margin: 'auto', minWidth: '350px', padding: '25px' }}>
      <Form.FormProvider onSubmit={handleSubmit} config={formConfig} initialValues={initialValues}>
        <StepOneForm />
      </Form.FormProvider>
    </Layout.Container>
  );
};
