import da from './da';
import de from './de';
import enUS from './en-US';
import frFR from './fr-FR';
import nlNL from './nl-NL';
import noNO from './no-NO';
import svSE from './sv-SE';

export default {
  da,
  de,
  'en-US': enUS,
  'fr-FR': frFR,
  'nl-NL': nlNL,
  'no-NO': noNO,
  'sv-SE': svSE,
};
